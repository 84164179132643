import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    OrderType: {
        name: 'Order type',
        required: true,
    },

    Payload: {
        name: 'Payload',
        required: true,
    },

    VFEnvironment: {
        name: 'Workflow namespace',
        required: true,
    },
})