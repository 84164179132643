<template>
    <div class="page-asap-workflows">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Workflows</h1>

                    <app-tabs v-model="tab" :tabs="tabs" :dropdownOnTablet="true" />

                    <app-loader v-if="loading" fixed-on="desktop" />

                    <app-error v-model="errors.show" :message="errors.message" />

                    <app-search-box
                        v-model="filter.word"

                        ref="search-box"

                        @search="onSearch"
                    />

                    <app-table
                        class="asap-workflows-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :clickable="true"

                        @row-click="goToWorkflowDetails"
                        @row-click-mobile="goToWorkflowDetails"
                    />

                    <div class="actions">
                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />

                        <button class="btn btn-primary btn-add-manual-order" @click="showCreateWorkflowDialog">Add Manual Order</button>
                    </div>
                </div>
            </div>
        </div>

        <app-dialog-create-asap-workflow
            v-if="dialogs.workflow.create.show"
            v-model="dialogs.workflow.create.show"

            @confirm="onDialogCreateWorkflowConfirm"
            @close="onDialogCreateWorkflowClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import store from '@/store'

import appTabs from '@/components/app-tabs'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appSearchBox from '@/components/app-search-box'

import appDialogCreateAsapWorkflow from './components/app-dialog-create-asap-workflow'

import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appTabs,
        appLoader,
        appError,
        appTable,
        appPagination,
        appSearchBox,

        appDialogCreateAsapWorkflow,
    },

    data() {
        return {
            filter: {
                word: '',

                sort: {
                    field: 'WorkflowStartedNanos',
                    order: 'desc',
                },
            },

            sort: [
                { field: 'Status',                 title: 'Status',     },
                { field: 'WorkflowStartedNanos',   title: 'Start Time', },
                { field: 'WorkflowCompletedNanos', title: 'End Time',   },
            ],

            cols: [
                { key: 'workflowId', title: 'Workflow ID', highlight: true                                           },
                { key: 'runId',      title: 'Run ID'                                                                 },
                { key: 'name',       title: 'Name'                                                                   },
                { key: 'status',     title: 'Status',      type: 'status', sort: { field: 'Status'                 } },
                { key: 'startTime',  title: 'Start Time',                  sort: { field: 'WorkflowStartedNanos'   } },
                { key: 'closeTime',  title: 'End Time',                    sort: { field: 'WorkflowCompletedNanos' } },
            ],

            workflows: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                workflow: {
                    create: {
                        show: false,
                    },
                },
            },

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.checkNamespaceID().then(namespace_id => {
                if (namespace_id != this.namespace_id) {
                    this.$router.push({ name: 'asap-workflows', params: { namespace_id } })
                } else {
                    if (this.$route.params.page) {
                        this.pagination.page = +this.$route.params.page
                    }

                    this.getWorkflows()
                }
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                this.$router.push({ name: 'main-dashboard' })
            })
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getWorkflows()
        },

        onPageChange(page) {
            this.getWorkflows(page)
        },

        getWorkflows(page) {
            this.loading = true

            const params = {
                'SPID':                     this.current_spid,

                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,

                // VFEnvironment: this.namespace_name,
                VFEnvironment: this.namespace_name.replace(/^VFNZ-ASAP-/i, ''),
            }

            if (this.filter.word) {
                params.SmartSearch = this.filter.word
            }

            this.$store.dispatch('api_asap/GetASAPWorkflowObjectsPaginated', params)
                .then(response => {
                    if (!response.ASAPWorkflowObjects || !response.PageInfo) {
                        return Promise.reject(response)
                    }

                    this.workflows = response.ASAPWorkflowObjects

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: {
                                namespace_id: this.namespace_id,
                                page: this.pagination.page,
                            },
                            query: this.$route.query,
                            replace: true
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.workflows = []

                    this.loading = false
                })
        },

        onSearch() {
            this.getWorkflows(1)
        },

        goToWorkflowDetails(row) {
            this.$router.push({
                name: 'asap-workflow-summary',

                params: {
                    namespace_name: this.namespace_name,
                    workflow_id: row.workflowId,
                    run_id: row.runId,
                },
            })
        },

        showCreateWorkflowDialog() {
            this.dialogs.workflow.create.show = true
        },
        onDialogCreateWorkflowConfirm() {
            this.dialogs.workflow.create.show = false

            this.getWorkflows()
        },
        onDialogCreateWorkflowClose() {
            this.dialogs.workflow.create.show = false
        },

        checkNamespaceID() {
            this.loading = true

            const params = {
                SPID: store.getters.current_spid,
            }

            return store.dispatch('getWorkflowNamespaces', params).then(namespaces => {
                const namespaces_ids = Object.keys(namespaces)

                if (namespaces_ids.length) {
                    if (this.namespace_id) {
                        if (this.namespace_id in namespaces) {
                            return Promise.resolve(this.namespace_id)
                        } else {
                            return Promise.reject(new Error('Namespace not found'))
                        }
                    } else {
                        return Promise.resolve( namespaces_ids[0] )
                    }
                } else {
                    return Promise.reject(new Error('Workflow namespaces are not found'))
                }
            }).finally(() => {
                this.loading = false
            })
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'workflow_namespaces',
            'workflow_execution_statuses',
        ]),

        namespace_id() {
            return this.$route.params.namespace_id
        },

        namespace_name() {
            return this.workflow_namespaces[ this.namespace_id ].name
        },

        rows() {
            return this.workflows.map(workflow => ({
                uuid: workflow.WorkflowID,

                workflowId: workflow.WorkflowID,
                runId: workflow.RunID,
                name: workflow.WorkflowName,
                status: {
                    status: this.workflow_execution_statuses[workflow.Status].color,
                    text: this.workflow_execution_statuses[workflow.Status].text,
                },
                startTime: formatDateNano(workflow.WorkflowStartedNanos),
                closeTime: workflow.WorkflowCompletedNanos ? formatDateNano(workflow.WorkflowCompletedNanos) : '?'
            }))
        },

        tabs() {
            const tabs = []

            for (const key in this.workflow_namespaces) {
                tabs.push({
                    id: key,
                    name: 'asap-workflows',
                    title: this.workflow_namespaces[key].title,
                    params: {
                        namespace_id: key,
                    },
                })
            }

            return tabs
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.namespace_id == tab.params.namespace_id)

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, params: { namespace_id: tab.params.namespace_id }, replace: true })
            },
        },
    },

    watch: {
        $route(to, from) {
            if (to.params.namespace_id != from.params.namespace_id) {
                if (to.params.namespace_id) {
                    this.$refs['search-box'].reset()

                    this.getWorkflows(1)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.page-asap-workflows {
    padding-bottom: 80px;

    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .app-tabs {
        margin-bottom: 34px;
    }

    & > .container {
        & > .row {
            & > .col-12 {
                .app-error {
                    margin-bottom: 24px;
                }
            }
        }
    }

    .app-search-box {
        margin-bottom: 30px;
    }

    .asap-workflows-table {
        @include table-cols-width((300px, 300px, 180px, 180px, 150px, 150px), true);
    }

    & > .container {
        & > .row {
            & > .col-12 {
                & > .actions {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .app-pagination,
                    .btn-add-manual-order {
                        margin-top: 30px;
                    }

                    .app-pagination {
                        flex-grow: 1;

                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-asap-workflows {
        .asap-workflows-table {
            @include table-cols-width((260px, 260px, 150px, 130px, 120px, 120px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        flex-direction: column;

                        .app-pagination {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-asap-workflows {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }

        .app-search-box {
            margin-bottom: 16px;
        }

        .asap-workflows-table {
            @include table-cols-width-mobile((90px, 184px), true);
        }

        & > .container {
            & > .row {
                & > .col-12 {
                    & > .actions {
                        .app-pagination,
                        .btn-add-manual-order {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>