<template>
    <app-dialog-info
        class="app-dialog-create-asap-workflow"

        v-if="show"
        v-model="show"

        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Add Manual Order</h2>
        </template>

        <template #body>
            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <div class="form-group">
                <div class="form-controls">
                    <app-dropdown-select
                        v-model="namespace_name"
                        :options="namespaces"

                        key-value="value"
                        key-title="title"

                        label="Workflow namespace"

                        :required="true"

                        :error="errors.fields.namespace_name"
                        :disabled="loading"

                        @change="errors.fields.namespace_name = null"
                    />
                </div>

                <div class="form-controls">
                    <app-dropdown-select
                        v-model="order_type"
                        :options="order_types"

                        key-value="value"
                        key-title="title"

                        label="Order type"

                        :required="true"

                        :error="errors.fields.order_type"
                        :disabled="loading"

                        @change="errors.fields.order_type = null"
                    />
                </div>

                <div class="form-controls">
                    <app-textarea
                        v-model="payload"

                        label="Payload"

                        :required="true"

                        :error="errors.fields.payload"
                        :disabled="loading"

                        @change="errors.fields.payload = null"
                    />
                </div>
            </div>

            <div class="actions">
                <button class="btn btn-primary" @click="confirm">Confirm</button>
                <button class="btn btn-primary btn-cancel" @click="close">Cancel</button>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appDropdownSelect from '@/components/app-dropdown-select'
import appTextarea from '@/components/app-textarea'

import ASAPWorkflowValidator from '@/validators/asap-workflow-validator'
import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appDropdownSelect,
        appTextarea,
    },

    data() {
        return {
            namespace_name: null,
            order_type: null,
            payload: null,

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            const namespace_id = this.$route.params.namespace_id
            this.namespace_name = this.workflow_namespaces[namespace_id].name

            /*
                this.order_type = this.order_types.length
                    ? this.order_types[0].value
                    : null
            */
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                order_type:     { rule: 'OrderType',     message: 'Please, select order type'         },
                payload:        { rule: 'Payload',       message: 'Please, enter valid XML payload'   },
                namespace_name: { rule: 'VFEnvironment', message: 'Please, select workflow namespace' },
            }

            for (const key in fields) {
                if (ASAPWorkflowValidator.isRuleExists(fields[key].rule)) {
                    if (ASAPWorkflowValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = ASAPWorkflowValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        confirm() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID:          this.current_spid,
                    OrderType:     this.order_type,
                    Payload:       this.payload,
                    VFEnvironment: this.namespace_name,
                }

                this.$store.dispatch('api_asap/CreateNewASAPWorkflow', params)
                    .then(() => {
                        this.$emit('confirm')

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Order submission was successful',
                            delay: 5000,
                        })
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Order submission was failed',
                            type: 'error',
                            delay: 5000,
                        })
                    })
            }
        },

        close() {
            this.$emit('close')
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'workflow_namespaces',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },

        namespaces() {
            const namespaces = []

            for (const key in this.workflow_namespaces) {
                namespaces.push({
                    value: this.workflow_namespaces[key].name,
                    title: this.workflow_namespaces[key].title,
                })
            }

            return namespaces
        },

        order_types() {
            return [
                {
                    value: 'VODAFONEORDER',
                    title: 'Vodafone order',
                },
                {
                    value: 'QUERYNETWORKSETTINGORDER',
                    title: 'Query network setting order',
                },
                {
                    value: 'UPDATENETWORKSETTINGORDER',
                    title: 'Update network setting order',
                },
            ]
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-create-asap-workflow {
    .app-dialog-body {
        .form-group {
            width: 100%;

            margin-bottom: 24px;

            .form-controls {
                margin-bottom: 24px;

                .app-textarea {
                    height: 144px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: space-between;

            width: 100%;

            .btn {
                max-width: 210px;

                margin: 0 15px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>